export interface INavigationRoute {
  name: string;
  displayName: string;
  meta: { icon: string };
  children?: INavigationRoute[];
}

export default {
  root: {
    name: "/",
    displayName: "navigationRoutes.home",
  },
  routes: [
    {
      name: "dashboard",
      displayName: "Статистика",
      meta: {
        icon: "vuestic-iconset-dashboard",
      },
    },
    // {
    //   name: 'users',
    //   displayName: 'menu.users',
    //   meta: {
    //     icon: 'group',
    //   },
    // },
    {
      name: "users",
      displayName: "Пользователи",
      meta: {
        onlyAdmin: true,
        icon: "group",
      },
    },
    {
      name: "ankets",
      displayName: "Анкеты",
      meta: {
        icon: "group",
      },
      children: [
        {
          name: "ankets.all",
          displayName: "Все",
          meta: {
            icon: "wallet",
          },
        },
        {
          name: "ankets.moderation",
          displayName: "Ожидают проверки",
          meta: {
            icon: "wallet",
          },
        },
      ],
    },
    {
      name: "salons",
      displayName: "Салоны",
      meta: {
        icon: "group",
      },
      children: [
        {
          name: "salons.all",
          displayName: "Все",
          meta: {
            icon: "wallet",
          },
        },
        {
          name: "salons.moderation",
          displayName: "Ожидают проверки",
          meta: {
            icon: "wallet",
          },
        },
      ],
    },
    {
      name: "regions",
      displayName: "Локации",
      meta: {
        icon: "place",
      },
      children: [
        {
          name: "cities.list",
          displayName: "Города",
          meta: {
            icon: "location",
          },
        },
        {
          name: "regions.list",
          displayName: "Районы",
          meta: {
            icon: "location",
          },
        },
        {
          name: "regions.orientir-categories",
          displayName: "Категории ориентиров",
          meta: {
            icon: "map",
          },
        },
        {
          name: "regions.orientirs",
          displayName: "Ориентиры",
          meta: {
            icon: "map",
          },
        },
      ],
    },
    {
      name: "categories",
      displayName: "Разделы",
      meta: {
        onlyAdmin: true,
        icon: "category",
      },
    },
    {
      name: "feedback",
      displayName: "Обратная связь",
      meta: {
        icon: "feed",
      },
      children: [
        {
          name: "reports",
          displayName: "Жалобы",
          meta: {
            icon: "feed",
          },
        },
        {
          name: "reviews",
          displayName: "Отзывы",
          meta: {
            icon: "feedback",
          },
        },
        {
          name: "contacts",
          displayName: "Обращения",
          meta: {
            icon: "feed",
          },
        },
      ],
    },
    {
      name: "tariffs",
      displayName: "Тарифы",
      meta: {
        onlyAdmin: true,
        icon: "money",
      },
    },
    {
      name: "seo",
      displayName: "SEO",
      meta: {
        icon: "tag",
      },
      children: [
        {
          name: "seo-templates",
          displayName: "Сео шаблоны",
          meta: {
            icon: "keyboard",
          },
        },
        {
          name: "tags",
          displayName: "Теги",
          meta: {
            icon: "tag",
          },
        },
        {
          name: "texts",
          displayName: "Текста",
          meta: {
            icon: "tag",
          },
        },
        {
          name: "seo-filters",
          displayName: "Сео для фильтров",
          meta: {
            icon: "search",
          },
        },
      ],
    },

    {
      name: "settings",
      displayName: "Настройки",
      meta: {
        onlyAdmin: true,
        icon: "settings",
      },
    },

    // {
    //   name: 'payments',
    //   displayName: 'menu.payments',
    //   meta: {
    //     icon: 'credit_card',
    //   },
    //   children: [
    //     {
    //       name: 'payment-methods',
    //       displayName: 'menu.payment-methods',
    //     },
    //     {
    //       name: 'pricing-plans',
    //       displayName: 'menu.pricing-plans',
    //     },
    //     {
    //       name: 'billing',
    //       displayName: 'menu.billing',
    //     },
    //   ],
    // },
    // {
    //   name: 'auth',
    //   displayName: 'menu.auth',
    //   meta: {
    //     icon: 'login',
    //   },
    //   children: [
    //     {
    //       name: 'login',
    //       displayName: 'menu.login',
    //     },
    //     {
    //       name: 'signup',
    //       displayName: 'menu.signup',
    //     },
    //     {
    //       name: 'recover-password',
    //       displayName: 'menu.recover-password',
    //     },
    //   ],
    // },
    //
    // {
    //   name: 'faq',
    //   displayName: 'menu.faq',
    //   meta: {
    //     icon: 'quiz',
    //   },
    // },
    // {
    //   name: '404',
    //   displayName: 'menu.404',
    //   meta: {
    //     icon: 'vuestic-iconset-files',
    //   },
    // },
    // {
    //   name: 'preferences',
    //   displayName: 'menu.preferences',
    //   meta: {
    //     icon: 'manage_accounts',
    //   },
    // },
    // {
    //   name: 'settings',
    //   displayName: 'menu.settings',
    //   meta: {
    //     icon: 'settings',
    //   },
    // },
  ] as INavigationRoute[],
};
