import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import AuthLayout from "../layouts/AuthLayout.vue";
import AppLayout from "../layouts/AppLayout.vue";
import { useUserStore } from "../stores/user-store";
import { getMe } from "../talentum/services/auth";
const routes: Array<RouteRecordRaw> = [
  {
    path: "/:pathMatch(.*)*",
    redirect: { name: "dashboard" },
  },
  {
    name: "admin",
    path: "/",
    component: AppLayout,
    redirect: { name: "dashboard" },
    meta: {
      requiredAuth: true,
    },
    children: [
      {
        name: "dashboard",
        path: "dashboard",
        component: () => import("../talentum/pages/Dashboard.vue"),
      },
      {
        name: "user",
        path: "users/:id",
        component: () => import("../talentum/pages/UserPage.vue"),
      },
      {
        name: "users",
        path: "users",
        component: () => import("../talentum/pages/UsersPage.vue"),
        meta: {
          roles: ["admin"],
        },
      },
      {
        name: "ankets",
        path: "ankets",
        component: () => import("../talentum/pages/CryptoCurrencies.vue"),
      },
      {
        name: "ankets.moderation",
        path: "ankets/moderation",
        meta: {
          type: "moderation",
        },
        component: () => import("../talentum/pages/New/AnketsView.vue"),
      },
      {
        name: "ankets.all",
        path: "ankets/all",
        meta: {
          type: "all",
        },
        component: () => import("../talentum/pages/New/AnketsView.vue"),
      },

      {
        name: "salons",
        path: "salons",
        component: () => import("../talentum/pages/New/Salons.vue"),
      },
      {
        name: "salons.moderation",
        path: "salons/moderation",
        meta: {
          type: "moderation",
        },
        component: () => import("../talentum/pages/New/Salons.vue"),
      },
      {
        name: "salons.all",
        path: "salons/all",
        meta: {
          type: "all",
        },
        component: () => import("../talentum/pages/New/Salons.vue"),
      },
      {
        name: "texts",
        path: "texts",
        component: () => import("../talentum/pages/New/Texts.vue"),
      },
      {
        name: "cities.list",
        path: "cities/list",
        component: () => import("../talentum/pages/New/Cities.vue"),
      },
      {
        name: "regions.list",
        path: "regions/list",
        component: () => import("../talentum/pages/New/Regions.vue"),
      },
      {
        name: "regions.orientirs",
        path: "regions/orientirs",
        component: () => import("../talentum/pages/New/Orientirs.vue"),
      },
      {
        name: "regions.orientir-categories",
        path: "regions/orientir-categories",
        component: () => import("../talentum/pages/New/OrientirCategories.vue"),
      },

      {
        name: "ankets.detail",
        path: "anket/:id",
        component: () => import("../talentum/pages/New/AnketDetail.vue"),
      },
      {
        name: "salon.detail",
        path: "salon/:id",
        component: () => import("../talentum/pages/New/SalonDetail.vue"),
      },
      {
        name: "reviews",
        path: "/reviews",
        component: () => import("../talentum/pages/New/Reviews.vue"),
      },
      {
        name: "reports",
        path: "/reports",
        component: () => import("../talentum/pages/New/Reports.vue"),
      },
      {
        name: "categories",
        path: "/categories",
        component: () => import("../talentum/pages/New/Categories.vue"),
      },
      {
        name: "tags",
        path: "/tags",
        component: () => import("../talentum/pages/New/Tags.vue"),
      },
      {
        name: "seo-filters",
        path: "/seo-filters",
        component: () => import("../talentum/pages/New/SeoFilters.vue"),
      },
      {
        name: "seo-templates",
        path: "/seo-templates",
        component: () => import("../talentum/pages/New/SeoTemplates.vue"),
      },
      {
        name: "contacts",
        path: "/contacts",
        component: () => import("../talentum/pages/New/Contacts.vue"),
      },
      {
        name: "tariffs",
        path: "tariffs",
        component: () => import("../talentum/pages/New/Tariffs.vue"),
      },
      {
        name: "settings",
        path: "settings",
        component: () => import("../talentum/pages/SettingsPage.vue"),
      },
    ],
  },
  {
    path: "/auth",
    component: AuthLayout,
    children: [
      {
        name: "login",
        path: "login",
        component: () => import("../pages/auth/Login.vue"),
      },
      {
        path: "",
        redirect: { name: "login" },
      },
    ],
  },
  {
    name: "404",
    path: "/404",
    component: () => import("../pages/404.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    }
    // For some reason using documentation example doesn't scroll on page navigation.
    if (to.hash) {
      return { el: to.hash, behavior: "smooth" };
    } else {
      window.scrollTo(0, 0);
    }
  },
  routes,
});

router.beforeEach(async (to, from, next) => {
  const token = localStorage.getItem("token");

  if (to.meta.requiredAuth && !token) {
    next("/auth/login");
  }

  next();
});

export default router;
